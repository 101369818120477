import * as React from "react";

function CircleNYTlogo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={139}
      height={186}
      viewBox="0 0 13.9 18.6"
      {...props}
    >
      <path d="M13.9 2.5C13.9.5 12 0 10.5 0v.3c.9 0 1.6.3 1.6 1a1.059 1.059 0 01-1.2 1 12.959 12.959 0 01-3.3-.8A13.275 13.275 0 004.1.7 3.27 3.27 0 00.7 3.9a2.318 2.318 0 001.5 2.2l.1-.2a1.054 1.054 0 01-.6-1 1.266 1.266 0 011.4-1.1 14.776 14.776 0 013.7.9 28.258 28.258 0 003.7.8v3.1L9 9.9v.1l1.5 1.3v4.3a4.618 4.618 0 01-2.5.6 4.93 4.93 0 01-3.9-1.6l4.1-2v-7l-5 2.2a6.685 6.685 0 012.6-2.9l-.1-.2A7.471 7.471 0 000 11.6a7.02 7.02 0 007 7 6.505 6.505 0 006.6-6.5h-.2a6.697 6.697 0 01-2.6 3.1v-4.1l1.6-1.3v-.1l-1.5-1.3v-3a2.858 2.858 0 003-2.9zm-8.7 11l-1.2.6a5.932 5.932 0 01-1.1-3.8 7.106 7.106 0 01.3-2.1l2.1-.9z" />
    </svg>
  );
}

export default CircleNYTlogo;
