function NotFound404() {
  return (
    <p>
      OOPS! Looks like you've gotten lost. Return to search by clicking on the
      logo above.
    </p>
  );
}

export default NotFound404;
